@import "../../../app/App";

.scroll-indicator__container {
    position: absolute;
    bottom: 18px;
    right: 20px;
    z-index: 1;

    .scroll-indicator__border {
        position: absolute;
        left: -2px;
        top: 8px;
        width: 114px;
        height: 50px;
        border-radius: 6px;
        z-index: 2;
        border: 3px solid lightgray;
    }

    .scroll-indicator {
        top: 10px;
        width: 110px;
        height: 46px;
        background: #ebecef;
        position: relative;
        border-radius: 5px;
        opacity: 0.8;

        .scroll-indicator__background {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $dark-grey-color;
            opacity: 0.5;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            height: 10px;
            width: 110px;
        }

        .scroll-indicator__horizontal-line {
            position: absolute;
            opacity: 0.3;
            left: 0;
            height: 2px;
            width: 110px;
            background-color: lightgray;
        }

        .scroll-indicator__bar {
            top: 3px;
            width: 80px;
            height: 40px;
            background: transparent;
            border: 3px solid #3cd7d1;
            border-radius: 4px;
            position: absolute;
            transform: translateX(-50%);
        }
    }
}

